import styles from "./navigation.module.css";

const Navigation = () => {
    return (
    <nav>
        <h1>Dominika Drąg </h1>
        <h2>Usługi Psychologiczne</h2>
    </nav>
    );
};
  
export default Navigation;